
import { Vue, Options } from 'vue-class-component';
import WorkflowLayout from '@/lib/layouts/WorkflowLayout.vue';
import { Encounter, FormSection, FormSubmission, Patient } from '@/models';
import { BaseButton, ButtonLink, SmartFormComplete, SmartFormSection } from '@/lib/components';
import VARefraction from '@/custom/menicon/views/encounter/VARefraction.vue';
import { EncounterData } from '@/helpers/encounter.helper';
import { countErrors, validate } from '@/helpers/validators.helper';
import SurveyResultCharts from '@/custom/menicon/SurveyResultCharts.vue';
import { SavingStatus } from '@/lib/constants';
import { isFeatureFlagEnabled } from '@/helpers/feature-flag.helper';
import { FEATURES } from '@/constants';
import { Laterality } from '@/custom/menicon/models';
import SlitLampWithoutLenses from '@/custom/menicon/views/encounter/SlitLampWithoutLenses.vue';

@Options({
  components: {
    VARefraction,
    SmartFormSection,
    WorkflowLayout,
    BaseButton,
    SmartFormComplete,
    ButtonLink,
    SurveyResultCharts,
    SlitLampWithoutLenses
  },
  props: {
    patientId: {
      type: String,
      required: true
    },
    journeyType: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      required: true
    },
    encounter: {
      type: Object,
      default: null
    },
    encounterData: {
      type: Object,
      default: null
    },
    formSubmission: {
      type: Object,
      default: null
    },
    initialEncounter: {
      type: Object,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    slitLampConfig: {
      type: Object,
      default: null
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    rules: {
      type: Object,
      default: () => ({})
    },
    status: {
      type: Number,
      default: SavingStatus.UNSET
    },
    name: {
      type: String,
      default: ''
    },
    laterality: {
      type: String,
      default: null
    },
    lateralities: {
      type: Array,
      default: () => [Laterality.right, Laterality.left]
    }
  },
  inheritAttrs: false
})
export default class EncounterPage extends Vue {
  encounterId!: string;
  patientId!: string;
  laterality!: Laterality | null;
  patient!: Patient;
  formSubmission!: FormSubmission | null;
  encounter!: Encounter;
  encounterData!: EncounterData | null;
  errors!: { [key: string]: string[] };
  rules!: { [key: string]: any };
  hygieneSectionId = '15d22730-924f-4e6b-8f88-24f353b9972a';

  get showVirtualDoctor() {
    return isFeatureFlagEnabled(FEATURES.VIRTUAL_DOCTOR);
  }

  get steps() {
    return [
      ...(this.showVirtualDoctor ? [this.$t('custom.menicon.virtual-doctor.name') as string] : []),
      this.$t('custom.menicon.encounter.va-over-refraction.name') as string,
      this.$t('custom.menicon.encounter.hygiene-questions.name') as string,
      this.$t('custom.menicon.encounter.slit-lamp.name') as string
    ];
  }

  get step(): number {
    return Number(this.$route.query.step || 1) - 1;
  }

  get hygieneQuestionsSection(): FormSection | undefined {
    return this.formSubmission?.form?.schema?.sections.find(
      (section: FormSection) => section.id === this.hygieneSectionId
    );
  }

  get currentSectionId(): string | null {
    if (this.step === this.getStep(this.$t('custom.menicon.encounter.hygiene-questions.name') as string)) {
      return this.hygieneSectionId;
    }
    return null;
  }

  get errorCount(): number {
    return countErrors(this.errors);
  }

  get smartFormCompleteProps() {
    return {
      heading: this.$t('custom.menicon.encounter.3-month-check-confirmation.heading') as string,
      body: this.$t('custom.menicon.encounter.3-month-check-confirmation.body') as string
    };
  }

  created() {
    this.$watch('encounter', () => {
      if (this.errorCount) {
        this.validateCurrentStep();
      }
    });
  }

  next() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('next', this.currentSectionId);
    }
  }

  complete() {
    this.validateCurrentStep();
    if (!this.errorCount) {
      this.$emit('complete', { sectionId: this.currentSectionId });
    }
  }

  validateCurrentStep() {
    let errors = {};
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.va-over-refraction.name') as string) &&
      this.rules?.vaRefraction
    ) {
      errors = validate(this.rules.vaRefraction, this.encounter);
    }
    if (
      this.step === this.getStep(this.$t('custom.menicon.encounter.slit-lamp.name') as string) &&
      this.rules?.slitLamp
    ) {
      errors = validate(this.rules.slitLamp, this.encounter);
    }
    this.$emit('setErrors', errors);
  }

  back() {
    this.$emit('back');
  }

  getStep(name: string): number {
    return this.steps.findIndex((step) => step === name);
  }
}
