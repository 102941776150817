import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "relative h-full w-full" }
const _hoisted_2 = {
  key: 0,
  class: "h-full max-w-screen-xl mx-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SurveyResultCharts = _resolveComponent("SurveyResultCharts")!
  const _component_VARefraction = _resolveComponent("VARefraction")!
  const _component_SmartFormSection = _resolveComponent("SmartFormSection")!
  const _component_SlitLampWithoutLenses = _resolveComponent("SlitLampWithoutLenses")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ButtonLink = _resolveComponent("ButtonLink")!
  const _component_SmartFormComplete = _resolveComponent("SmartFormComplete")!
  const _component_WorkflowLayout = _resolveComponent("WorkflowLayout")!

  return (_openBlock(), _createBlock(_component_WorkflowLayout, {
    name: _ctx.name,
    steps: _ctx.steps,
    loading: _ctx.loading,
    "current-step": _ctx.step,
    patient: _ctx.patient,
    status: _ctx.status,
    onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('exit')))
  }, {
    actions: _withCtx(() => [
      _createVNode(_component_BaseButton, {
        size: "large",
        color: "ghost",
        loading: _ctx.loading,
        type: "button",
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.back()))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('platform.common.back')), 1)
        ]),
        _: 1
      }, 8, ["loading"]),
      (_ctx.step < _ctx.steps.length - 1)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            size: "large",
            class: "ml-2",
            "right-icon": "arrow-right",
            loading: _ctx.loading,
            onClick: _cache[5] || (_cache[5] = _withModifiers(($event: any) => (_ctx.next()), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.next')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
        : (_openBlock(), _createBlock(_component_BaseButton, {
            key: 1,
            "right-icon": "arrow-right",
            size: "large",
            color: "success",
            class: "ml-2",
            loading: _ctx.loading,
            onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.complete()))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.complete')), 1)
            ]),
            _: 1
          }, 8, ["loading"]))
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_ctx.encounter)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.virtual-doctor.name')))
                ? (_openBlock(), _createBlock(_component_SurveyResultCharts, {
                    key: 0,
                    class: "p-6 md:p-10",
                    "patient-id": _ctx.patientId,
                    "journey-type": _ctx.journeyType,
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["patient-id", "journey-type", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.va-over-refraction.name')))
                ? (_openBlock(), _createBlock(_component_VARefraction, {
                    key: 1,
                    loading: _ctx.loading,
                    title: _ctx.$t('custom.menicon.encounter.va-over-refraction.title'),
                    data: _ctx.encounterData.vaRefraction,
                    errors: _ctx.errors,
                    "required-lateralities": _ctx.lateralities,
                    "va-unit": _ctx.encounter.va_unit,
                    onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update', $event)))
                  }, null, 8, ["loading", "title", "data", "errors", "required-lateralities", "va-unit"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.hygiene-questions.name')) && _ctx.hygieneQuestionsSection)
                ? (_openBlock(), _createBlock(_component_SmartFormSection, {
                    key: 2,
                    class: "p-6 md:p-10",
                    "form-submission-id": _ctx.formSubmission.id,
                    "patient-id": _ctx.patientId,
                    section: _ctx.hygieneQuestionsSection,
                    next: _ctx.next,
                    back: _ctx.back
                  }, null, 8, ["form-submission-id", "patient-id", "section", "next", "back"]))
                : _createCommentVNode("", true),
              (_ctx.step === _ctx.getStep(_ctx.$t('custom.menicon.encounter.slit-lamp.name')))
                ? (_openBlock(), _createBlock(_component_SlitLampWithoutLenses, _mergeProps({ key: 3 }, _ctx.encounterData?.slitLamp, {
                    config: _ctx.slitLampConfig,
                    errors: _ctx.errors,
                    loading: _ctx.loading,
                    laterality: _ctx.laterality,
                    "onUpdate:conditions": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_conditions: $event
          }))),
                    "onUpdate:isNoneChecked": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update', {
            is_slit_lamp_condition_none: $event
          }))),
                    "onUpdate:details": _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update', {
            slit_lamp_details: $event
          })))
                  }), null, 16, ["config", "errors", "loading", "laterality"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "default")
      ]),
      (_ctx.showConfirmationModal)
        ? (_openBlock(), _createBlock(_component_SmartFormComplete, {
            key: 0,
            heading: _ctx.smartFormCompleteProps.heading,
            body: _ctx.smartFormCompleteProps.body,
            "is-glyph": "",
            "icon-color": "success"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_BaseButton, {
                size: "large",
                block: "",
                color: "primary",
                class: "mt-6",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('backToPrescription')))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.back-to-patient')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ButtonLink, {
                size: "large",
                block: "",
                color: "muted",
                class: "mt-3",
                to: "/"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.patient.exit-to-list')), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["heading", "body"]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["name", "steps", "loading", "current-step", "patient", "status"]))
}